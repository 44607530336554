@media all and (min-width: 320px) and (max-width: 768px){
    aside{
        display: none!important; 
    }
    .table-wid{
        width: 100%!important;
        table-layout: fixed;
        word-wrap: break-word!important;
    }
    .id{
        width: 2.2rem!important;
    }
    .category{
        display: none!important;
    }
    .dropdown{
        display: block!important;
    }
    .dropdown > select{
        width: 100%;
    }
    .dropdown > button{
        width: 100%;
        margin-top: 10px;
    }
}