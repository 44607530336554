@media all and (min-width: 320px) and (max-width: 768px){
    .container{
        padding: 0!important;
    }
    .form{
        width: 100%!important;
    }
    .form > span {
        padding: 0!important;
        text-align: center!important;
    }
}